import React from 'react'

import Layout from '../components/layout'

import headerImage from './../images/header/leda-homecare-help-at-home.png'
import bassetlaw from './../images/main/bassetlaw.png'
import notts from './../images/main/notts.png'
import mansfield from './../images/main/mansfield.png'
import bassetlawccg from './../images/main/bassetlawccg.png'
import mlcsu from './../images/main/mlcsu.jpg'

const LocalAuthorities = () => (
  <Layout>
    <div
      className="header-image"
      style={{ backgroundImage: `url(${headerImage})` }}
    />
    <div
      className="container"
      style={{ marginTop: '100px', marginBottom: '60px' }}
    >
      <div className="row">
        <div className="twelve columns">
          <h2 className="center-text">Local Authorities</h2>
          {/* <p className="center-text">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industrys standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p> */}
        </div>
      </div>
      <div className="row">
        <div className="columns two">
          <div
            style={{
              marginTop: '20px',
              backgroundImage: `url(${bassetlaw})`,
              backgroundSize: 'contain',
              height: '200px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </div>
        <div className="columns two">
          <div
            style={{
              marginTop: '20px',
              backgroundImage: `url(${notts})`,
              backgroundSize: 'contain',
              height: '200px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </div>
        <div className="columns three">
          <div
            style={{
              marginTop: '20px',
              backgroundImage: `url(${bassetlawccg})`,
              backgroundSize: 'contain',
              height: '200px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </div>
        <div className="columns two">
          <div
            style={{
              marginTop: '20px',
              backgroundImage: `url(${mansfield})`,
              backgroundSize: 'contain',
              height: '200px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </div>
        <div className="columns three">
          <div
            style={{
              marginTop: '20px',
              backgroundImage: `url(${mlcsu})`,
              backgroundSize: 'contain',
              height: '200px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default LocalAuthorities
